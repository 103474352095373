@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}

.amaranth-font {
  font-family: "Amaranth", Sans-serif, serif;
}
.amaranth {
  @extend .amaranth-font;
  color: white;
}

.allura-font {
  font-family: "Amaranth", Sans-serif, serif;
}
.allura {
  @extend .allura-font;
  color: white;
}
.allura-big {
  font-size: 3rem;
  @media (max-width: 1199.98px) {
    font-size: 2rem;
  }
  font-weight: 500;
}

h1, h2 {
  @extend .amaranth;
  color: #414C36;
  font-weight: 600
}

h1 {
  font-size: 2.5rem;
  @media (max-width: 1199.98px) {
    font-size: 2rem;
  }
}

.h1-big {
  font-size: 3rem;
  @media (max-width: 1199.98px) {
    font-size: 2.5rem;
  }
}

.h1-home {
  font-weight: 900;
  font-size: 4.5rem !important;
  @media (max-width: 1199.98px) {
    font-size: 3.5rem !important;
  }
}
