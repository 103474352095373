.navbar-only, .navbar-main {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

  .nav-item {
    .nav-link {
      color: #414C36;
      font-weight: 600;
      font-size: 0.9rem !important;
      margin-top: 2px;
    }
    &.active .nav-link {
      color: #aabf55;
    }

    &.dropdown {
      .nav-link {
        color: #414C36;
        font-weight: 600;
      }
    }
  }
}
