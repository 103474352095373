.text-secondary {
  color: #808B75;
}

.bg-dark-green {
  background-color: #414C36;
}

p {
  color: #414C36;
}

.btn-home {
  @extend .amaranth-font;
  border-radius: 50vw;
  text-transform: uppercase;
  padding: 7px 20px;
}

.btn-home-dark-green {
  @extend .btn-home;
  border-color: #414C36;
  background-color: #414C36;
  color: whitesmoke;
}

.btn-home-white {
  @extend .btn-home;
  border-color: whitesmoke;
  background-color: whitesmoke;
  color: #414C36;
}
