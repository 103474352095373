$active-color: #AABF55;
$primary: #AABF55;

$page-spacer-height: 71px;
$page-spacer-height-mobile: 65px;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "color";
@import "footer";
@import "font";
@import "navbar";
@import "product-page";

$progressbar-stepped-color: #AABF55;
@import "~@shopcrate/shopcrate-framework/lib/scss/shopcrate";

@import "~react-loading-skeleton/dist/skeleton.css";

.card {
    @extend .border-primary;
}

.homepage-icon {
    font-size: 4rem;
    color: #AABF55;
}

.homepage-highlight {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.carousel-indicators li {
    width: 16px;
    height: 16px;
    border: 1px #fff;
    border-radius: 8px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.jumbotron-hero {
    padding: 0;
    background-color: white;
    background-position: center;
    background-size: cover;

    .hero-mobile-fader {
        width: 100%;
        padding-top: 10vh;
        padding-bottom: 10vh;
        display: flex;
        align-content: center;

        @media (max-width: 991.98px) {
            background-color: rgba(255,255,255,0.5);
        }

        .container {
            vertical-align: middle;
        }
    }

    h1 {
        vertical-align: middle;
        font-size: 3.5rem;
        font-weight: 900;
        @media (max-width: 767.98px) {
            font-size: 2.5rem;
        }
    }
}
